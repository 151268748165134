import React, { useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "../css/flaticon.min.css";
import "../css/fontawesome-5.14.0.min.css";
import "../Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Digital = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const {t}=useTranslation()
  return (
    <>
      <Helmet>
        <title>
        Best Digital Marketing Services in Chennai - The Tech Horse
        </title>
        <meta
          name="description"
          content="Grow your business with expert digital marketing services in Chennai. SEO, social media, PPC & more to boost your online presence. Get a free consultation!"
        />
      </Helmet>
      <Header />
      <section className="page-banner-area digital-banner pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center">
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">
              {t("digiBannerH")} <span>{t("digiBannerSpan")}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <a>{t("digiBannerHome")}</a>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#fff" }}
                >
                  {t("digiBannerHomeNav")}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            className="circle digitalcircle wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <section
        className="ww-do-area pt-100 pb-130 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="section-title mb-40 wow fadeInRight delay-0-2s">
              <span className="sub-title style-two mb-15 digitalHead">
                {t("digiWhatH")}
              </span>
              <h2>{t("digiWhatSub")}</h2>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="ww-do-content rmb-65">
                <div className="feature-three-wrap">
                  <div className="feature-item-three wow fadeInRight delay-0-3s">
                    <i className="fi fi-tr-url" style={{ color: "#aac47a" }} />
                    <div className="content">
                      <h4>{t("digiseoLi1H")}</h4>
                      <p>
                      {t("digiseoLi1Para")}</p>
                    </div>
                  </div>
                  <div className="feature-item-three color-two wow fadeInRight delay-0-4s">
                    <i
                      className="fi fi-ts-megaphone"
                      style={{ color: "#aac47a" }}
                    />
                    <div className="content">
                      <h4>{t("digiseoLi2H")}</h4>
                      <p>
                      {t("digiseoLi2Para")}</p>
                    </div>
                  </div>
                  <div className="feature-item-three color-three wow fadeInRight delay-0-5s">
                    <i
                      className="fi fi-tr-ad-paid"
                      style={{ color: "#aac47a" }}
                    />
                    <div className="content">
                      <h4>{t("digiseoLi3H")}</h4>
                      <p>
                      {t("digiseoLi3Para")}</p>
                    </div>
                  </div>
                  <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                    <i
                      className="fi fi-tr-it-alt"
                      style={{ color: "#aac47a" }}
                    />
                    <div className="content">
                      <h4>{t("digiseoLi4H")}</h4>
                      <p>
                      {t("digiseoLi4Para")}</p>
                    </div>
                  </div>
                  <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                    <i
                      className="fi fi-ts-blueprint"
                      style={{ color: "#aac47a" }}
                    />
                    <div className="content">
                      <h4>{t("digiseoLi5H")}</h4>
                      <p>
                      {t("digiseoLi5Para")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="feature-three-wrap">
                <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                  <i
                    className="fi fi-ts-diamond"
                    style={{ color: "#aac47a" }}
                  />
                  <div className="content">
                    <h4>{t("digiseoLi6H")}</h4>
                    <p>
                    {t("digiseoLi6Para")}{" "}
                    </p>
                  </div>
                </div>
                <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                  <i
                    className="fi fi-tr-photo-video"
                    style={{ color: "#aac47a" }}
                  />
                  <div className="content">
                    <h4>{t("digiseoLi7H")}</h4>
                    <p>
                    {t("digiseoLi7Para")}{" "}
                    </p>
                  </div>
                </div>
                <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                  <i
                    className="fi fi-tr-shield-virus"
                    style={{ color: "#aac47a" }}
                  />
                  <div className="content">
                    <h4>{t("digiseoLi8H")}</h4>
                    <p>
                    {t("digiseoLi8Para")}</p>
                  </div>
                </div>
                <div className="feature-item-three color-four wow fadeInRight delay-0-6s">
                  <i
                    className="fi fi-tr-leaderboard-trophy"
                    style={{ color: "#aac47a" }}
                  />
                  <div className="content">
                    <h4>{t("digiseoLi9H")}</h4>
                    <p>
                    {t("digiseoLi9Para")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="feature-with-image-area bgc-black text-white py-130 rel z-1"
        style={{ backgroundColor: "#aac47a" }}
      >
        <div className="container">
          <div className="feature-with-image">
            <div className="feature-image wow fadeInLeft delay-0-2s">
              <img src="/assets/digi1.webp" alt="Feature" loading="lazy" />
            </div>
            <div className="feature-content wow fadeInRight delay-0-2s">
              <h3>{t("digiSata1H")}</h3>
              <p style={{ color: "#fff", opacity: 1 }}>
              {t("digiSata1Para")}
              </p>
            </div>
            <div className="feature-shape">
              <img
                src="/images/shapes/feature-shape.png"
                alt="Shape"
                loading="lazy"
              />
            </div>
          </div>
          <div className="feature-with-image">
            <div className="feature-shape">
              <img
                src="/images/shapes/feature-shape.png"
                alt="Shape"
                loading="lazy"
              />
            </div>
            <div className="feature-content wow fadeInRight delay-0-2s">
              <h3>{t("digiSata2H")}</h3>
              <p style={{ color: "#fff", opacity: 1 }}>
              {t("digiSata2Para")}
              </p>
            </div>
            <div className="feature-image wow fadeInLeft delay-0-2s">
              <img src="/assets/digi2.webp" alt="Feature" loading="lazy" />
            </div>
          </div>
          <div className="feature-with-image">
            <div className="feature-image wow fadeInLeft delay-0-2s">
              <img src="/assets/digi3.webp" alt="Feature" loading="lazy" />
            </div>
            <div className="feature-content wow fadeInRight delay-0-2s">
              <h3>{t("digiSata3H")}</h3>
              <p style={{ color: "#fff", opacity: 1 }}>
              {t("digiSata3Para")}
              </p>
            </div>
            <div className="feature-shape">
              <img
                src="/images/shapes/feature-shape.png"
                alt="Shape"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="feature-area-four pb-100 pt-100 rel z-1">
        <div className="container">
          <div className="section-title text-center mb-50 wow fadeInUp delay-0-2s">
            <span className="sub-title style-two mb-15 digitalHead">
              {t("digiCoreH")}
            </span>
            <h2>{t("digiCoreSub")}</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-3s">
                <h4>
                  <i className="flaticon-drag-and-drop digitalHead" />
                  <a>{t("digicore1H")}</a>
                </h4>
                <p>
                {t("digicore1Para")}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-5s">
                <h4>
                  <i className="flaticon-responsive-1 digitalHead" />
                  <a>{t("digicore2H")}</a>
                </h4>
                <p>
                {t("digicore2Para")}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-7s">
                <h4>
                  <i className="flaticon-email digitalHead" />
                  <a>{t("digicore3H")}</a>
                </h4>
                <p>
                {t("digicore3Para")}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-3s">
                <h4>
                  <i className="flaticon-monitor digitalHead" />
                  <a>{t("digicore4H")}</a>
                </h4>
                <p>
                {t("digicore4Para")}</p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-5s">
                <h4>
                  <i className="fi fi-tr-bullseye-arrow digitalHead" />
                  <a>{t("digicore5H")}</a>
                </h4>
                <p>
                {t("digicore5Para")}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="feature-item-four wow fadeInUp delay-0-7s">
                <h4>
                  <i className="fi fi-ts-ranking-stars digitalHead" />
                  <a>{t("digicore6H")}</a>
                </h4>
                <p>
                {t("digicore6Para")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-two-area py-130 rel z-1">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="why-choose-two-image rmb-65 wow fadeInUp delay-0-4s">
                <img
                  src="/assets/digi4.webp"
                  alt="why choose us"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="why-choose-two-content wow fadeInUp delay-0-2s">
                <div className="section-title mb-35">
                  <span className="sub-title style-two digitalHead mb-15">
                    {t("digiChooseH")}
                  </span>
                  <h2>{t("digiChooseSub")}</h2>
                </div>
                <ul className="list-style-one digitaluL">
                  <li>
                    <div className="content">
                      <h5>{t("digiChoose1H")}</h5>
                      <p>
                      {t("digiChoose1Para")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("digiChoose2H")}</h5>
                      <p>
                      {t("digiChoose2Para")}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h5>{t("digiChoose3H")}</h5>
                      <p>
                      {t("digiChoose3Para")}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-to-action-area bgc-black pt-80 pb-50"
        style={{ backgroundColor: "#aac47a" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-white mb-25 wow fadeInUp delay-0-2s">
                <h2>
                  {t("digiunlockH")}
                </h2>
                <p>
                {t("digiunlockPara")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <Link to="/contact-us">
                <a className="theme-btn digitalLearnbtn style-two mb-30 wow fadeInUp delay-0-4s">
                {t("digiunlockBtn")} <i className="fas fa-angle-double-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Digital;
