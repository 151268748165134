import PageBanner from "./PageBanner";


import Header from "./Home/Header";
import Footer from "./Footer/Footer";
import React from "react";
import "./css/flaticon.min.css";
import "./css/fontawesome-5.14.0.min.css";
import "./Main.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import './App.css'

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Understand how The TechHorse handles your data responsibly. Learn about our information collection, usage, and privacy practices."
        />
      </Helmet>
      <Header />
      <section
        className="page-banner-area  pt-245 rpt-150 pb-170 rpb-100 rel z-1 bgc-lighter text-center"
        style={{ backgroundColor: "#cef6ff" }}
      >
        <div className="container">
          <div className="banner-inner rpt-10">
            <h1 className="page-title wow fadeInUp delay-0-2s">Privacy Policy</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" style={{ color: "#000" }}>
                  Privacy Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="banner-shapes">
          <div
            style={{ backgroundColor: "#67e4ff" }}
            className="circle  wow zoomInLeft delay-0-2s"
            data-wow-duration="2s"
          />
          <img
            className="shape-one"
            src="/images/shapes/hero-shape1.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape-two"
            src="/images/shapes/hero-shape2.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      <div className="privacy-policy container py-5">
        <h1>Privacy Policy for The TechHorse</h1>

        <Section title="1. Information We Collect">
          <p>We collect various types of information to provide and improve our services effectively.</p>
          <SubSection title="1.1 Personal Information">
            <p>Personal information is any data that can identify you directly or indirectly, such as:</p>
            <ul>
              <li>
                <strong>Contact Information:</strong> Name, email address, phone number, and any other details you voluntarily provide through forms, inquiries, or newsletters.
              </li>
              <li>
                <strong>Account Information:</strong> If applicable, login credentials and preferences for accessing specific areas of the site.
              </li>
            </ul>
          </SubSection>
          <SubSection title="1.2 Non-Personal Information">
            <p>Non-personal information is collected automatically when you visit our site and includes:</p>
            <ul>
              <li>Technical Data: IP address, browser type, operating system, and device details.</li>
              <li>Usage Data: Information about how you interact with our website, such as pages visited, time spent, and navigation patterns.</li>
            </ul>
          </SubSection>
          <SubSection title="1.3 Cookies and Similar Technologies">
            <p>
              Cookies are small text files stored on your device that help us enhance your browsing experience. We may also use web beacons, pixels, and analytics tools to collect non-personal information.
            </p>
          </SubSection>
        </Section>

        <Section title="2. How We Use Your Information">
          <p>We use your information to provide a seamless and personalized experience. The specific purposes include:</p>
          <SubSection title="2.1 Service Delivery">
            <ul>
              <li>Responding to inquiries and support requests.</li>
              <li>Processing requests for services or subscriptions.</li>
              <li>Offering tailored content and resources.</li>
            </ul>
          </SubSection>
          <SubSection title="2.2 Communication">
            <ul>
              <li>Sending newsletters, updates, or promotional materials.</li>
              <li>Informing you about policy changes, new features, or relevant services.</li>
            </ul>
          </SubSection>
          <SubSection title="2.3 Analytics and Improvements">
            <ul>
              <li>Understanding user behavior to enhance website functionality.</li>
              <li>Monitoring traffic patterns and troubleshooting technical issues.</li>
            </ul>
          </SubSection>
          <SubSection title="2.4 Legal and Security Obligations">
            <ul>
              <li>Ensuring compliance with applicable laws and regulations.</li>
              <li>Detecting and preventing fraudulent or malicious activities.</li>
            </ul>
          </SubSection>
        </Section>

        <Section title="3. How We Protect Your Information">
          <p>We take the security of your data seriously and employ a range of measures to protect it, including:</p>
          <ul>
            <li><strong>Encryption:</strong> Data is encrypted during transmission to safeguard it from interception.</li>
            <li><strong>Access Controls:</strong> Restricted access to personal information only to authorized personnel.</li>
            <li><strong>Regular Audits:</strong> Periodic reviews of security systems and processes to prevent vulnerabilities.</li>
          </ul>
        </Section>

        <Section title="4. Sharing Your Information">
          <p>We respect your privacy and do not sell your personal data. However, we may share your information under the following circumstances:</p>
          <SubSection title="4.1 Service Providers">
            <p>We may share data with trusted third parties who perform functions such as website hosting, analytics, or email delivery. These providers are obligated to maintain the confidentiality of your information.</p>
          </SubSection>
          <SubSection title="4.2 Legal Compliance">
            <p>We may disclose your information to comply with legal requirements or respond to lawful requests by public authorities.</p>
          </SubSection>
          <SubSection title="4.3 Business Transactions">
            <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</p>
          </SubSection>
        </Section>

        <Section title="5. Your Privacy Choices">
          <p>You have several rights regarding your personal data, including:</p>
          <SubSection title="5.1 Access and Correction">
            <p>You can request access to the personal information we hold about you and request corrections if it is inaccurate or incomplete.</p>
          </SubSection>
          <SubSection title="5.2 Deletion">
            <p>You can request the deletion of your personal data, subject to legal or contractual obligations.</p>
          </SubSection>
          <SubSection title="5.3 Marketing Preferences">
            <p>You can opt out of receiving promotional emails or updates by clicking the "Unsubscribe" link in our communications or contacting us directly.</p>
          </SubSection>
        </Section>

        <Section title="6. Cookies and Tracking">
          <p>We use cookies and similar technologies to enhance your experience.</p>
          <SubSection title="6.1 Types of Cookies Used">
            <ul>
              <li><strong>Essential Cookies:</strong> Necessary for the basic functionality of the site.</li>
              <li><strong>Performance Cookies:</strong> Help us understand website usage and improve performance.</li>
              <li><strong>Functional Cookies:</strong> Remember your preferences and settings.</li>
            </ul>
          </SubSection>
          <SubSection title="6.2 Managing Cookies">
            <p>You can adjust your browser settings to manage or disable cookies. Note that disabling cookies may affect site functionality.</p>
          </SubSection>
        </Section>

        <Section title="7. Third-Party Links">
          <p>Our website may contain links to external sites. We are not responsible for the privacy practices or content of third-party websites. Please review their privacy policies before providing any personal information.</p>
        </Section>

        <Section title="8. Children's Privacy">
          <p>The TechHorse does not knowingly collect information from children under the age of 13. If you believe a child has provided us with personal data, please contact us, and we will take immediate action to delete the information.</p>
        </Section>

        <Section title="9. Policy Updates">
          <p>This Privacy Policy may be updated periodically to reflect changes in our practices or legal requirements. The updated policy will be posted on this page with a revised "Effective Date."</p>
        </Section>

        <Section title="10. Contact Us">
          <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please reach out to us:</p>
          <ul>
            <li><strong>Email:</strong> info@thetechhorse.com</li>
            <li><strong>Phone:</strong> +91 8925790019</li>
            <li><strong>Address:</strong> Royapettah, Chennai, Tamil Nadu 600014</li>
          </ul>
        </Section>
      </div>
      <Footer />
    </>
  );
};


 

const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="section my-4">
    <h2>{title}</h2>
    {children}
  </div>
);

const SubSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="subsection my-3">
    <h3>{title}</h3>
    {children}
  </div>
);

export default PrivacyPolicy;
