import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
} from "react-router-dom";
import Header from "../Home/Header";
import { Button } from "@mui/material";
import Footer from "../Footer/Footer";

const data = [
  {
    slug: "digital-marketing-stratgies-for-detists",
    date: "Mar 2025",
    title: "Digital Marketing Strategies for Dentists - Top Guide",
    image: "/assets/tthb-de.jpg",
    text: "Every dental practice needs a powerful digital footprint in our current digital period. Digital marketing as a tool provides strong capabilities for dental practices to obtain new patients while establishing trust relations and expanding their operations. ",
  },
  {
    slug: "keywords-in-digital-marketing",
    date: "Mar 2025",
    title: "Keywords in Digital Marketing – Best Strategies for SEO Success",
    image: "/assets/tthb-dk.jpeg",
    text: "Digital marketing succeeds through keywords because they enhance online visibility along with effective audience targeting. Your SEO effectiveness together with website traffic increases and conversion rates improve when you learn how to effectively use keywords in digital marketing.",
  },
  {
    slug: "india's-best-website-development-company",
    date: "Feb 2025",
    title: "India's Best Website Development Company -  The Tech Horse ",
    image: "/assets/tthb-wb.jpg",
    text: "A business must build a robust digital platform presence for success during the current digital age. Sites with well-organized design aspects garner trust while simultaneously attracting more visitors and achieving better user responses and more sales. As an industry leader The Tech Horse provides innovative solutions through website development services which benefit all organizational scales in India.",
  },
  {
    slug: "advantages-of-a-website-design-for-your-business",
    date: "Feb 2025",
    title: "The Advantages of a Website design for Your Business",
    image: "/assets/Advan.jpg",
    text: "A business must fully realize that website development in the digital landscape has moved from being a luxury into becoming essential. Potential customers typically view your business website before they develop any other impression",
  },
  {
    slug: "the-tech-horse-elevating-website-development-in-chennai",
    date: "Jan 2025",

    title: "The Tech Horse: Elevating Website Development in Chennai",
    image: "/assets/Web dev.jpg",
    text: "Businesses across all sizes need robust online operations to succeed in the current technological period. The Tech Horse operates as a dependable website development agency specifically serving clients in Chennai.",
  },
  {
    slug: "best-seo-service-company-how-to-choose-the-right-one",
    date: "oct 2024",

    title: "Best SEO Service Company – How to Choose the Right One",
    image: "/assets/SEO.jpg",
    text: "The foundation of how websites appear in search results is named Search Engine Optimization (SEO). SEO enables your website to secure better positions on search results thus improving both visitor quantity and customer prospects regardless of your company size. The large number of agencies providing SEO services generates the need to locate the most suitable SEO service provider.",
  },

];

const CardGrid: React.FC = () => {
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
  return (
    <>
      <div className="hd">
        <Header />
      </div>

      <Container className="card-div my-5 ">
        <Row className="g-5 justify-content-center">
          {data.map((item) => (
            <Col
              key={item.slug}
              md={4}
              className="d-flex justify-content-center"
            >
              <Card className="mt-5 ">
                <Card.Img
                  variant="top"
                  src={item.image}
                  loading="lazy"
                  style={{
                    height: "220px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className=""
                />


                

                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text className="truncate-text">{item.text}</Card.Text>
                  <Link to={`/blogs/${item.slug}`}>
                    <Button className="theme-btn read-btn" >
                      {" "}
                      Read More
                    </Button>
                  </Link>
                  <p className="mt-2 mb-0">{item.date}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer/>

    </>
  );
};

export default CardGrid;
