import React, { useState } from "react";
import "../Main.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [lettermail, setletterMail] = useState("");

  const handleNewletter = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post("https://tth-website-api.onrender.com/news-letter", {
        newletterEmail: lettermail,
      });
      setletterMail("");
      alert("subscription Done");
    } catch (error: any) {
      console.log(error.message);
      alert("Got internal error");
    }
  };

  const { t } = useTranslation();


  return (
    <>
      <footer
        className="main-footer footer-two pt-80 bgc-lighter"
        style={{ backgroundColor: "#f7f9fd" }}
      >
        <div className="container">
          <div className="row justify-content-xl-between justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div className="footer-widget widget_about me-md-5 wow fadeInUp delay-0-2s">
                <div className="footer-logo mb-25">
                  <Link to="/">
                    <a>
                      <img
                        src="/assets/tth_logo.png"
                        alt="Logo"
                        style={{ height: "70px" }}
                      />
                    </a>
                  </Link>
                </div>
                <p style={{ lineHeight: "22px" }}>{t("footerpara")}</p>
            
                <div className="social-style-two pt-5">
                  <a
                    href="https://www.facebook.com/profile.php?id=61550827268393"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="https://twitter.com/home?lang=en-in" target="_blank">
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/the-tech-horse-946a12299/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="footer-widget widget_nav_menu wow fadeInUp delay-0-4s">
                <h4 className="footer-title">{t("quickLinkHead")}</h4>
                <ul className="list-style-two">
                  <li>
                    <Link to="/web-development">{t("quickLink1")}</Link>
                  </li>
                  <li>
                    <Link to="/about-us">{t("quickLink6")}</Link>
                  </li>
                  <li>
                    <Link to="/mobile-app-development">{t("quickLink2")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{t("quickLink7")}</Link>
                  </li>
                  <li>
                    <Link to="/software-development">{t("quickLink3")}</Link>
                  </li>
                  <li>
                    <Link to="/dev-ops">{t("quickLink8")}</Link>
                  </li>
                  <li>
                    <Link to="/cloud-computing">{t("quickLink4")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("quickLink9")}</Link>
                  </li>
                 
                  <li>
                    <Link to="/digital-marketing">{t("quickLink5")}</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-8">
              <div className="footer-widget widget_newsletter wow fadeInUp delay-0-6s">
                <h4 className="footer-title">{t("newsLetterHead")}</h4>
                <p>{t("newspara")}</p>
                <form onSubmit={handleNewletter} action="#">
                  <input
                    type="email"
                    placeholder={`${t("newsletterplaceHolder")}`}
                    required
                    onChange={(e) => setletterMail(e.target.value)}
                  />
                  <button type="submit" className="theme-btn">
                    {t("newsletterBtn")}{" "}
                    <i className="fas fa-angle-double-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-30 pt-25 pb-10">
          <div className="container">
            <div className="copyright-text text-center">
              <p>{t("copyRights")}</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
