import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import CoreFeatures from "./CoreFeatures";
import "../Main.css";
import { Helmet } from "react-helmet";

const HomeMain = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Best Web Development company in Chennai - The Tech Horse</title>
        <meta
          name="description"
          content="The Tech Horse is the Best web development company in Chennai. Offers expert digital marketing services with SEO, PPC, and custom website design."
        />
      </Helmet>
      <CoreFeatures />
      <Footer />
    </>
  );
};

export default HomeMain;
